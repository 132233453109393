import React, {Component} from 'react';
import {Button, CircularProgress, TextField} from '@mui/material';
import {connect} from 'react-redux';

import {loginRequest} from '../../actions/login';
import {GAEventTracker} from '../../lib/googleAnalytics';
import {getCookie, getFromLocalStorage, setCookie, validateLoginRequest} from '../../lib/utils';
import Footer from '../WebSite/footer';
import Header from '../WebSite/header';

class Login extends Component {
  constructor(){
    super();
    this.state = {
      userIdMobile : '',
      password : '',
      btnDisabled : false,
      error: null
    };
    this.loginHandler = this.loginHandler.bind(this);
  }
  componentWillMount(){
    if(getCookie("userToken")){
      let localStorageData = getFromLocalStorage();
      if(localStorageData.role == "systemadmin"){
        this.props.history.push("/admin-panel");
      } else{
        this.props.history.push("/sms");
      }
    }
  }
  componentDidUpdate(){
    let {loginResponse} = this.props;
    if(loginResponse && loginResponse.success){
      setCookie("userToken",loginResponse.data.token, 30);
      setCookie("userId",loginResponse.data.userId, 30);
      if(loginResponse.data.baseData.role == "systemadmin"){
        this.props.history.push("/admin-panel");
      } else{
        this.props.history.push("/sms");
      }
    }
  }
  componentWillReceiveProps(nextProps){
    if(this.state.btnDisabled === true && nextProps.loginResponse){
      this.setState({btnDisabled : false});
    }
  }
  loginHandler(){
    let {userIdMobile,password} = this.state;
    const params = {userIdMobile : userIdMobile.toLowerCase(),password}
    const validation = validateLoginRequest(params)
    if (validation.isValidated) {
      this.setState({btnDisabled : true, error: null});
      GAEventTracker({category : 'Sign-in',action:'Click'});
      this.props.loginRequest(params);
    } else {
      this.setState({error: validation.errorJson})
    }
  }
  enterLoginHandler(eKey){
    if(eKey === 'Enter'){
      this.loginHandler();
    }
  }
  render() {
    const {loginResponse} = this.props;
    let {userIdMobile,password,btnDisabled, error} = this.state;
    const isMobile = window.isMobile();
    return (
      <div>
        {process.env.REACT_APP_IS_RESELLER !== 'true' ? <Header headerClass="ed-white" /> : null}
        <div className="login container-lg" style={{display: 'flex', alignItems: 'center'}}>
          {!isMobile &&
            <div style={{flex: 0.5, display: 'flex', justifyContent: 'center'}}>
              <img src={process.env.REACT_APP_LOGIN_ILL} style={{maxWidth: '80%'}} alt='login'></img>
            </div>
          }
          <div style={{flex: isMobile ? 1 : '0.5', marginLeft: 'auto'}}>
            <p style={{fontSize: 16, marginBottom: 20, color: '#616161'}}>LOGIN</p>
            <h4 style={{fontSize: 36}}>Welcome</h4>
            <p style={{fontSize: 14, marginTop: 8, color: '#616161'}}>Login to send and manage SMS</p>
            <div>
              <div style={{display: 'flex', gap: 16, flexDirection: 'column', paddingTop: 16}}>
                <TextField
                  label="Enter your user name"
                  placeholder='Username'
                  fullWidth={true}
                  value={userIdMobile}
                  size='small'
                  onKeyPress={e => this.enterLoginHandler(e.key)}
                  onChange={e => {this.setState({userIdMobile : e.target.value, error: null})}}
                  error={!!error?.userIdMobile}
                  variant='standard'
                  shrink={!!userIdMobile}
                  helperText={error?.userIdMobile || ''} />
                <TextField
                  label="Enter your password"
                  type="password"
                  placeholder="Password"
                  fullWidth={true}
                  size='small'
                  value={password}
                  onKeyPress={e => this.enterLoginHandler(e.key)}
                  onChange={e => this.setState({password : e.target.value, error: null})}
                  variant='standard'
                  error={!!error?.password}
                  shrink={!!password}
                  helperText={error?.password || ''} />
              </div>
              {
                loginResponse && !loginResponse.success && loginResponse.err ?
                  <p className="login-error">{loginResponse.err.msg}</p>
                  : null
              }
              <div style={{display: 'flex', gap: 16, paddingTop: 36}}>
                <div>
                  <p style={{display:'inline-block',fontSize:12,verticalAlign:'middle'}}>Don't have an account yet?</p>
                  <a href="/contact-us" onClick={e=>{GAEventTracker({category : 'Sign-up', action :'Click'})}}>
                    <Button>Sign Up</Button>
                  </a>
                </div>
                <div style={{marginLeft: 'auto'}}>
                  {
                    btnDisabled ?
                      <CircularProgress size={20} thickness={2} />
                      : null
                  }
                  <Button disabled={btnDisabled} onClick={this.loginHandler.bind(this)} variant='contained' size="large">
                    Login
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {process.env.REACT_APP_IS_RESELLER !== 'true' ? <Footer /> : null}
      </div>
    )
  }
}
// Login.contextTypes = {
//   router: PropTypes.object.isRequired
// };
function mapStateToProps(state) {
  return {loginResponse: state.loginResponse.loginResponse};
}
export default connect(mapStateToProps,{loginRequest})(Login);
