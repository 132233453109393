import React, {useEffect} from 'react'
import Grid from '@mui/material/Grid'
import {connect} from 'react-redux'

import {dashboardDetails, dashboardSMSStats} from '../../actions/dashboard'
import {getContactGroup} from '../../actions/user'
import Contact from './Contact'
import LastActivity from './LastActivity'
import OfferUpdates from './OfferUpdates'
import SMSBalance from './SMSBalance'
import SMSUsage from './SMSUsage'
import SenderIDList from './SenderIDList'

const NewDashboard = ({dashboardDetails, dashboardSMSStats, getContactGroup, dashboardData, smsStats}) => {
  useEffect(() => {
    dashboardDetails()
    dashboardSMSStats()
    getContactGroup()
  }, [dashboardDetails, dashboardSMSStats, getContactGroup])

  return (
    <div className='dashboard-container'>
      <Grid container spacing={2}>
        <Grid item xl={4} lg={5} md={7} xs={12}>
          <SMSBalance data={dashboardData} />
        </Grid>
        <Grid item xl={3} lg={3} md={5} xs={12}>
          <SenderIDList data={dashboardData} />
        </Grid>
        <Grid item xl={5} lg={4} md={12} xs={12}>
          <LastActivity />
        </Grid>
        <Grid item xs={12}>
          <SMSUsage data={smsStats} dashboardData={dashboardData} />
        </Grid>
        <Grid item lg={8} md={7} xs={6}>
          <OfferUpdates />
        </Grid>
        {process.env.REACT_APP_IS_RESELLER !== 'true' &&
          <Grid item lg={4} md={5} xs={6}>
            <Contact />
          </Grid>
        }
      </Grid>
    </div>
  )
}

function mapStateToProps(state){
  return{
    dashboardData : state.dashboard.dashboard,
    smsStats: state.dashboard.smsStats,
    allGroupDetails : state.userResponse.contactGroupResponse
  }
}
export default connect(mapStateToProps,{dashboardDetails,dashboardSMSStats,getContactGroup})(NewDashboard)
